<template>
  <div v-if="isList" class="organization">
    <div class="content-header">
      <div class="header-title">
        <span>组织管理</span>
      </div>
      <div class="header-operate">
        <img src="@/assets/img/organization/add.png">
        <el-button class="operate-button" v-role="'RS-M03-F01'" type="text" @click="importVisible = true">
          批量导入
        </el-button>
        <el-button class="operate-button" v-role="'RS-M03-F03'" type="text" @click="handleCreateOrganization">
          创建组织
        </el-button>
      </div>
    </div>
    <div class="organization-name">
      <span>单位名称:</span><span @click="handleTenantDetails()">{{ tenant.name}}</span>
      <p-select class="p-select" :model="filter" :limit="1" label-width="110px" @select="handleQuery"
                position="left">
        <el-form-item prop="name" label="组织名称" slot="1">
          <el-input v-model="filter.name"/>
        </el-form-item>
        <el-form-item prop="uniqueCode" label="组织编码" slot="1">
          <el-input v-model="filter.uniqueCode"/>
        </el-form-item>
        <el-form-item prop="isElec" label="数电试点企业" slot="1">
          <el-select v-model="filter.isElec" placeholder="请选择">
            <el-option
                v-for="item in elecData"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </p-select>
    </div>
    <div class="content-main">
      <el-table
        v-loading="recordLoading"
        :data="tableData"
        style="width:100%"
        row-key="id"
        border
        stripe
        :header-cell-style="handleHeaderCellStyle"
        :default-expand-all="false"
        max-height="440"
        :expand-row-keys="[SecondaryNode]"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="name" label="组织名称" align="left"></el-table-column>
        <el-table-column prop="uniqueCode" label="组织编码"></el-table-column>
        <el-table-column prop="auditStatus" label="认证状态" :formatter="handleAuditStatusFormat"></el-table-column>
        <el-table-column prop="isTaxpayer" label="是否开票组织">
          <template slot-scope="scope">
            <span>{{fmtOpenStatus(scope.row)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="isElec" label="数电试点企业" :formatter="elecFormatter"></el-table-column>
        <el-table-column prop="operate" label="操作">
          <template slot-scope="scope">
            <el-button type="text" style="width: 60px" @click="handleViewOrganization(scope.row)">
              查看组织
            </el-button>
            <el-button v-role="'RS-M03-F02'" type="text" style="width: 60px"
                       @click="handleEditOrganization(scope.row)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog append-to-body title="导入组织" :visible.sync="importVisible" width="500px" :show-close="false">
        <organization-import @handleCancelImportDialog="importVisible = false"></organization-import>
      </el-dialog>
    </div>
  </div>
  <router-view v-else></router-view>
</template>

<script>
import PSelect from '@/components/pdt/PSelect'
import {AUDIT_STATUS} from '@/content/organization'
import {tableStyle} from '../../../util/mixins'
import OrganizationImport from './OrganizationImport';
import {getTenant, ReqOrganizationTree} from '@/service/organization'

export default {
  name: 'Organization',
  mixins: [tableStyle],
  components: { PSelect, OrganizationImport },
  data () {
    return {
      elecData: [
        {
          value: '',
          label: '全部'
        },
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ],
      filter: {
        name: '',
        uniqueCode: '',
        isElec: ''
      },
      isList: true,
      tableData: [],
      tenant: {},
      recordLoading: false,
      SecondaryNode: '',
      isTaxpayer: {},
      importVisible: false
    }
  },
  watch: {
    '$route.path': {
      handler () {
        if (this.$route.path == '/system/organization') {
          this.isList = true
        }
        this.handleQuery()
      },
      immediate: true
    }
  },
  created () {
    this.initDicts()
    this.handleTenant()
  },
  methods: {
    elecFormatter(row, col) {
      return row.isElec ? '是' : '否'
    },
    initDicts() {
      this.getDicts('sys_normal_yes_no').then(({data}) => this.isTaxpayer = data)
    },
    // 组织列表
    async handleQuery () {
      this.recordLoading = true
      const { success, data } = await ReqOrganizationTree(this.filter)
      this.recordLoading = false
      if (success) this.tableData = data
      if (data && data.length > 0) this.SecondaryNode = '' + data[0].id
    },
    // 组织创建
    handleCreateOrganization () {
      this.isList = false
      this.$router.push('/system/organization/create')
    },
    // 组织详情
    handleViewOrganization (row) {
      this.isList = false
      this.$router.push({ path: '/system/organization/details', query: { id: row.id } })
    },
    // 组织编辑
    handleEditOrganization (row) {
      this.isList = false
      this.$router.push({ path: '/system/organization/create', query: { id: row.id, isEdit: 'true' } })
    },
    // 认证状态格式化
    handleAuditStatusFormat (row, column) {
      let auditStatus = row[column.property]
      for (let status of AUDIT_STATUS) {
        if (auditStatus == status.value) {
          return status.label
        }
      }
      return auditStatus
    },
    //处理枚举请求
    handleTenant () {
      getTenant().then(res => {
        if (res.success) {
          this.tenant = res.data
        }
      })
    },
    //跳转到租客详情页面
    handleTenantDetails () {
      this.isList = false
      this.$router.push('/system/organization/TenantDetails')
    },

    fmtOpenStatus (row) {
      return this.getDictLabel(this.isTaxpayer, row.isTaxpayer)
    }
  }
}
</script>

<style lang="scss" scoped>
  .organization {
    width: 100%;
    height: 100%;
    background-color: #fff;

    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 24px;
      border-bottom: 1px solid #e9e9e9;

      .header-title {
        font-size: 16px;
        font-weight: 500;
        color: #333;

        span:before {
          content: "|";
          display: inline-block;
          font-size: 16px;
          background-color: #333;
          margin-right: 10px;
        }
      }

      .header-operate {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 4px;

        .operate-button {
          font-size: 16px;
          font-weight: 500;
        }

        img {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }

        .el-button {
          padding: 0;
        }
      }
    }

    .content-main {
      margin: 0;
      padding: 24px;
    }

    .organization-name {
      margin-top: 24px;
      padding-left: 24px;

      span:nth-child(1) {
        width: 80px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      span:nth-child(2) {
        margin-left: 12px;
        width: 192px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1890FF;
      }
    }
  }

  ::v-deep .el-table td,
  ::v-deep .el-table th {
    text-align: left;
    padding: 8px 0;
  }

  .p-select {
    text-align: left;
    padding: 16px 24px 0 0;
  }
</style>
